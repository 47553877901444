var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "550" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { style: "height: 550px;  " },
        [
          _c(
            "v-card-title",
            { style: "max-height: 63px !important; margin-top: -3%;" },
            [
              _vm.fileProcess
                ? _c("v-col", { staticClass: "ml-n4", attrs: { cols: "11" } }, [
                    _vm._v("File process id: " + _vm._s(_vm.fileProcess.id)),
                  ])
                : _vm._e(),
              _c(
                "v-col",
                { staticClass: "ml-4", attrs: { cols: "1" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "" }, on: { click: _vm.close } },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-3", style: "background-color: #e7e7fa" },
            _vm._l(_vm.history, function (details) {
              return _c(
                "v-card-text",
                {
                  key: details.id,
                  style:
                    "background-color: #ffffff; padding: 12px 0px 0px 18px;",
                },
                [
                  _c("div", { staticClass: "row h-row" }, [
                    _c("div", { staticStyle: { "margin-left": "3px" } }, [
                      details.eventId
                        ? _c("div", [
                            _vm._v(" Evento: " + _vm._s(details.eventId) + " "),
                            _c("br"),
                          ])
                        : _c("div", [_vm._v(" Resumo "), _c("br")]),
                      _vm._v(
                        " Total: " +
                          _vm._s(details.sum) +
                          " - " +
                          _vm._s(details.percentage) +
                          "% "
                      ),
                      _c("br"),
                      _vm._v(
                        " Tempo médio: " + _vm._s(details.average_time) + " "
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-left": "69%",
                          position: "absolute",
                        },
                      },
                      [
                        _vm._v(" sucesso: " + _vm._s(details.success) + " "),
                        _c("br"),
                        _vm._v(" erro: " + _vm._s(details.error) + " "),
                        _c("br"),
                        _vm._v(" ignorados: " + _vm._s(details.ignored) + " "),
                      ]
                    ),
                  ]),
                  _c("v-divider", { staticClass: "my-3" }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }