<template>
  <v-dialog v-model="show" max-width="550">
    <v-card :style="'height: 550px;  '">
      <v-card-title :style="'max-height: 63px !important; margin-top: -3%;'">
        <v-col v-if="fileProcess" cols="11" class="ml-n4">File process id: {{ fileProcess.id }}</v-col>
        <v-col cols="1" class="ml-4">
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>

      <v-card-text :style="'background-color: #e7e7fa'" class="pa-3">
        <v-card-text
          v-for="details in history"
          v-bind:key="details.id"
          :style="'background-color: #ffffff; padding: 12px 0px 0px 18px;'"
        >
          <div class="row h-row">
            <div style="margin-left: 3px">
              <div v-if="details.eventId">
                Evento: {{ details.eventId }}
                <br />
              </div>
              <div v-else>
                Resumo
                <br />
              </div>
              Total: {{ details.sum }} - {{ details.percentage }}%
              <br />
              Tempo médio: {{ details.average_time }}
            </div>
            <div style="margin-left: 69%; position: absolute">
              sucesso: {{ details.success }}
              <br />
              erro: {{ details.error }}
              <br />
              ignorados: {{ details.ignored }}
            </div>
          </div>
          <v-divider class="my-3"></v-divider>
        </v-card-text>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import eSocialService from '@/services/eSocialService.js';

export default {
  name: 'ModalUploadArquivoHistory',

  data() {
    return {
      fileProcess: null,
      history: [],
      show: false,
    };
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.$on('open', this.open);
      this.$on('close', this.close);
    },

    async open(fileProcess) {
      this.fileProcess = fileProcess;
      if (this.fileProcess) {
        this.history = await this.getProcessHistory(fileProcess.id);
      }
      this.show = true;
    },

    async getProcessHistory(fileProcessId) {
      let response = await eSocialService.eSocialUpload.getProcessHistory(fileProcessId);
      return response.data;
    },

    close() {
      this.show = false;
    },
  },
};
</script>
<style>
</style>
